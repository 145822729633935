import * as echarts from "echarts"
import Vue from "vue"
import Router from "vue-router"
import { color } from "echarts"
import "echarts/map/js/china" //引入中国地图
// 定义全局样式
const fontSize = {
  legentSize: 12, //legend 字体大小
  titleSize: 16, // 标题的大小
  xLableSize: 16, // 坐标刻度字体
  shaftName: 14, // 坐标轴上的名称字体
}
// 定义全局字体颜色
const colorSyle = {
  titleColor: "#fff", // 标题的颜色
  shaftColor: "rgb(145,187,218)", // 坐标轴上的颜色
  legentColor: "rgb(145,187,218)", // 图列字体的颜色
  toolboxColor: "rgb(145,187,218)", // 工具组件的颜色
  splitLine: "rgb(145,187,218)", // 柱形图中的分割线，不是折线图中的折线颜色
  // globalColor: ["#5470c6", "#fac858", "#ee6666", "#73c0de", "rgb(242,95,19)", "#9a60b4", "#ea7ccc"], // 定义调色盘，用于没有赋值的柱子渲染颜色
  // globalColor: ["#ff9b6a", "#7294d4", "#fef263", "#349e69", "#ea7ccc", "#b8f1ed", "#bce7cb", "#ff9d6e", "#b5b3eb"], // 定义调色盘，用于没有赋值的柱子渲染颜色
  saveImageBgColor: "rgb(35,39,71)", // 保存图片的背景色
}
// 其他的全局属性
const other = {
  legendDirection: "horizontal", // 设置lengget的方向
  titleDirection: "left", // 标题的位置
  toolboxDirection: 20, // toolbox的右边距
  toolboxMarginTop: 13, // toolbox的右边距
}

// 总维修人数
export function totalMaintenance(dats) {
  // 保留两位小数
  // let e = 30
  let e = Math.floor(dats.artisanPer * 100) / 100
  return {
    tooltip: {
      trigger: "item",
    },
    //环形中间文字
    graphic: [
      //第一行文字
      //内容 + 位置
      {
        type: "text",
        left: "center",
        top: "48%",
        style: {
          //value当前进度
          text: e + "%",
          textAlign: "center",
          fill: "#fff",
          fontSize: 24,
        },
      },
    ],

    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["70%", "90%"],
        avoidLabelOverlap: false,
        hoverAnimation: false, // 去掉鼠标悬停动画
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: false,
            fontSize: "40",
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
          normal: {
            show: false,
          },
        },
        data: [
          {
            value: e,
            name: "Search Engine",
            itemStyle: {
              normal: {
                color: "#ff9845",
              },
            },
          },
          {
            value: 100 - e,
            name: "Video Ads",
            itemStyle: {
              normal: {
                color: "#858a9f",
              },
            },
          },
        ],
      },
    ],
  }
}
// 总设备数
export function totalDevice(dats) {
  // 保留两位小数
  let e = Math.floor(dats.devicePer * 100) / 100
  return {
    tooltip: {
      trigger: "item",
    },

    //环形中间文字
    graphic: [
      //第一行文字
      //内容 + 位置
      {
        type: "text",
        left: "center",
        top: "48%",
        style: {
          //value当前进度
          text: e + "%",
          textAlign: "center",
          fill: "#fff",
          fontSize: 24,
        },
      },
    ],
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["70%", "90%"],
        avoidLabelOverlap: false,
        hoverAnimation: false, // 去掉鼠标悬停动画
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: false,
            fontSize: "40",
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
          normal: {
            show: false,
          },
        },
        data: [
          {
            value: e,
            name: "Search Engine",
            itemStyle: {
              normal: {
                color: "#ff9845",
              },
            },
          },
          {
            value: 100 - e,
            name: "Video Ads",
            itemStyle: {
              normal: {
                color: "#858a9f",
              },
            },
          },
        ],
      },
    ],
  }
}
// 柱状图
export function bareCharts(dats) {
  let obj = {
    appointOrderNum: dats.appointOrderNum, // 派单，
    inMaintenanceNum: dats.inMaintenanceNum, // 维修中
    inTransportNum: dats.inTransportNum, // 派送中
    accomplishNum: dats.accomplishNum, //完成
    otherNum: dats.otherNum, // 其他
  }
  let data = Object.values(obj)
  return {
    grid: {
      top: 25,
      bottom: 40,
      left: 60,
    },
    color: ["#3ba1fe", "#fbd437", "#4fcc78", "#5ad8a6", "#eaa674"],
    colorBy: "data",
    xAxis: {
      type: "category",
      data: ["派单", "维修中", "派送中", "完成", "其他"],
      axisLabel: {
        fontSize: 16,
        interval: 0,
        color: "#858a9f",
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        fontSize: 16,
        color: "#858a9f",
      },
      axisLine: {
        show: false,
      },
      splitNumber: 3,
      splitLine: {
        show: true,
        lineStyle: {
          color: "#858a9f",
          width: 0.5,
        },
      },
    },
    series: [
      {
        data,
        type: "bar",
        barWidth: 20,
        colorBy: "data",
        // 柱子上方提示的文字
        label: {
          show: true,
          position: "top",
          color: "#fff",
        },
        itemStyle: {
          normal: {
            color: function (params) {
              let colorList = ["#3ba1fe", "#fbd437", "#4fcc78", "#5ad8a6", "#eaa674"]
              return colorList[params.dataIndex]
            },
          },
        },
      },
    ],
  }
}
// 地图
export function mapCharts(dats) {
  let arr = []
  dats.forEach((item) => {
    arr.push({
      name: item.deviceName,
      jingweidu: item.jingweidu,
      // jingweidu: item.deviceLatLng.map((i) => +i),
      value: 100,
      url: item.deviceQrImg,
      id: item.id,
      deviceName: item.deviceName, // 设备名称
      deviceTypeName: item.deviceTypeName, // 设备名称
      deviceRuntime: item.deviceRuntime, // 设备运行时长
      deviceStatus: item.deviceStatus, // 设备状态码
    })
  })
  var series = []
  ;[["西安", arr]].forEach(function (item, i) {
    series.push({
      name: item[0] + " Top3",
      type: "effectScatter",
      coordinateSystem: "geo",
      zlevel: 2,
      symbolSize: function (val) {
        return val[2] / 8
      },
      itemStyle: {
        normal: {
          color: "#ff9845",
        },
        emphasis: {
          areaColor: "#2B91B7",
        },
      },
      rippleEffect: {
        // brushType: "stroke",
        color: "#fff",
        scale: 1.5,
      },
      data: item[1].map(function (dataItem) {
        return {
          // name: dataItem[1].name,
          // value: geoCoordMap[dataItem[1].name].concat([dataItem[1].value]),
          name: dataItem.deviceTypeName,
          // value: geoCoordMap[dataItem[1].name].concat([dataItem[1].value]),
          value: dataItem.jingweidu
            .concat([dataItem.value])
            .concat([
              {
                deviceName: dataItem.deviceName, // 设备名称
                deviceRuntime: dataItem.deviceRuntime, // 设备运行时长
                deviceStatus: dataItem.deviceStatus, // 设备状态码
                deviceTypeName: dataItem.deviceTypeName,
              },
            ])
            .concat([dataItem.id]),
        }
      }),
    })
  })
  // var $imgs = [
  //   { area: "北京", url: "https://e3f49eaa46b57.cdn.sohucs.com/2021/9/1/18/13/MTAwMTIyXzE2MzA0OTExOTUwODU=.png" },
  //   { area: "上海", url: "https://e3f49eaa46b57.cdn.sohucs.com/2021/9/1/18/13/MTAwMTIyXzE2MzA0OTExOTUwODU=.png" },
  //   { area: "拉萨", url: "https://e3f49eaa46b57.cdn.sohucs.com/2021/9/1/18/13/MTAwMTIyXzE2MzA0OTExOTUwODU=.png" },
  // ]
  let $imgs = arr
  return {
    tooltip: {
      backgroundColor: "#35344b",
      trigger: "item",
      formatter: function (params, ticket, callback) {
        let $pna = params.name
        let res = ""
        for (let i = 0; i < $imgs.length; i++) {
          if ($imgs[i].deviceTypeName === $pna) {
            res = "<span style='text-align:center;display:block;height:10px'>" + $pna + '</span><br/><img style="width:130px;height:130px" src="' + $imgs[i].url + '"/>'
            break
          }
        }
        setTimeout(function () {
          callback(ticket, res)
        }, 100)
        return res
      },
    },
    geo: {
      map: "china",
      label: {
        emphasis: {
          show: true,
          color: "#fff",
        },
      },
      markPoint: {
        symbol: "rect",
      },
      roam: false,
      //   放大我们的地图
      zoom: 1.2,
      data: {
        symbol: "arrow",
      },
      itemStyle: {
        normal: {
          areaColor: "rgba(12,86,166,1)",
          borderColor: "rgba(0,1,22, 1)",
          borderWidth: 1,
        },
        emphasis: {
          areaColor: "rgb(105,167,240)",
        },
      },
    },
    series: series,
  }
}

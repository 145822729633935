/*
 * @Descripttion:
 * @version:
 * @Author: shuqing
 * @Date: 2021-10-12 14:16:09
 * @LastEditTime: 2021-12-03 14:45:10
 */
import axios from "axios"
// import store from "@/store"
import {
  Message
} from "element-ui"

const baseConfig = {
  // baseURL: "http://192.168.2.156:8100",
  baseURL: "https://bd.tigermac.cn/", //线上
  // baseURL: "http://localhost:8100", //
  timeout: 3000,
  transformRequest: [
    function (data) {
      data = JSON.stringify(data)
      return data
    },
  ],
  //   在传给then之前修改响应数据
  transformResponse: [
    function (data) {
      if (typeof data === "string" && data.startsWith("{")) {
        data = JSON.parse(data)
      }
      return data
    },
  ],
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
}

const httpService = axios.create(baseConfig)
const http = axios.create(baseConfig)

// 请求拦截函数
const requestIterceptor = (config) => {
  const token = localStorage.getItem("login_token1")
  console.log(token)
  //   config.headers["Authorization"] = token
  if (token) {
    config.headers["Authorization"] = "Bearer " + token
  }
  return config
}

// 请求拦截器
// http.interceptors.request.use(requestIterceptor, (error) => {
//   return Promise.reject(error)
// })
// httpService.interceptors.request.use(requestIterceptor, (error) => {
//   return Promise.reject(error)
// })

const errorHandle = (status, message) => {
  switch (status) {
    case 404:
      Message({
        message: message,
        type: "error",
      })
      break
    case 20001:
      Message({
        message: message,
        type: "error",
      })
      break
    default:
      Message({
        message: message,
        type: "error",
      })
  }
}

// 响应拦截器
httpService.interceptors.response.use((response) => {
  const data = response.data
  if (response.status === 200 || data.code === 0) {
    return Promise.resolve(data)
  } else {
    errorHandle(response.status === 200 ? data.code : response.status, data.msg)
  }
  return Promise.reject(error)
})

http.interceptors.response.use((response) => {
  return Promise.resolve(response)
})

export default httpService
export {
  http
}
<!--
* @description 表格组件
* @fileName TableList.vue
* @author liulian
* @date 2021/05/15 15:13:45
!-->
<template>
  <div class="table-container">
    <el-table
      :data="data"
      :border="border"
      :stripe="stripe"
      empty-text="暂无数据"
      style="width: 100%"
      :show-header="showHeader"
      :tooltip-effect="tooltipTheme"
      @selection-change="handleSelectionChange"
    >
      <!-- 选择框列 -->
      <el-table-column v-if="selection" type="selection" align="center"></el-table-column>
      <!-- 排序列 -->
      <el-table-column v-if="indexShow" width="100" label="序号" align="center">
        <template slot-scope="scope">
          <div>
            <span>{{ scope.$index + 1 }}</span>
          </div>
        </template>
      </el-table-column>
      <template v-for="(item, index) in columns">
        <!-- 特殊列处理 -->
        <el-table-column
          v-if="item.render"
          :key="index"
          :prop="item.prop ? item.prop : null"
          :align="item.align ? item.align : null"
          :fixed="item.fixed ? item.fixed : null"
          :label="item.label ? item.label : null"
          :show-overflow-tooltip="item.tooltip"
          :class-name="className"
          :resizable="item.resizable?item.resizable:null"
          :sortable="item.sortable ? item.sortable : false"
          :width="item.width ? item.width : null"
        >
          <template slot-scope="scope">
            <exSlot :render="item.render" :row="scope.row" :index="scope.$index" :column="item" />
          </template>
        </el-table-column>
        <!-- 正常列 -->
        <el-table-column
          v-else
          :key="index"
          :prop="item.prop ? item.prop : null"
          :align="item.align ? item.align : null"
          :fixed="item.fixed ? item.fixed : null"
          :label="item.label ? item.label : null"
          :class-name="className"
          :resizable="item.resizable?item.resizable:null"
          :show-overflow-tooltip="item.tooltip"
          :sortable="item.sortable ? item.sortable : false"
          :width="item.width ? item.width : null"
        />
      </template>

      <el-table-column v-if="isEdit === true" label="操作" align="center" width="200" :fixed="fixed">
        <template slot-scope="scope">
          <el-button type="primary" @click="editClick(scope.row)">编辑</el-button>
          <el-button type="danger" @click="deleteClick(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="pagination"
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
// 自定义内容的组件
var exSlot = {
  functional: true,
  props: {
    row: Object,
    render: Function,
    index: Number,
    column: {
      type: Object,
      default: null
    }
  },
  // render 函数
  render: (h, context) => {
    const params = {
      row: context.props.row,
      index: context.props.index
    }
    if (context.props.columns) params.columns = context.props.columns
    return context.props.render(h, params)
  }
}
export default {
  name: 'TableList',
  components: { exSlot },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    tooltipTheme: {
      type: String,
      default: 'dark'
    },
    // 是否显示表头
    showHeader: {
      type: Boolean,
      default: true
    }
    ,
    // 是否添加排序列
    indexShow: {
      type: Boolean,
      default: true
    },
    // 是否显示选择框列
    selection: {
      type: Boolean,
      default: false,
    },
    // 字段名
    columns: {
      type: Array,
      default: () => []
    },
    // 是否含有边框
    border: {
      type: Boolean,
      default: false
    },

    // 是否显示斑马条纹
    stripe: {
      type: Boolean,
      default: false
    },
    // 是否是可以编辑的表格
    isEdit: {
      type: Boolean,
      default: false
    },
    // 是否固定右侧一列,只对右侧操作栏起作用
    fixed: {
      type: String,
      default: ''
    },

    // 是否显示分页
    pagination: {
      type: Boolean,
      default: false
    },
    total: {
      required: false,
      type: Number
    },
    page: {
      type: Number,
      default: 1 // 默认第一页
    },
    limit: {
      type: Number,
      default: 20 // 默认每页20条
    },
    pageSizes: {
      type: Array,
      // default: [10, 20, 30, 50]
      default: function () {
        return [1, 2, 3, 5] // 默认显示可选的每页多少条数据
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ''
    },
    render: {
      type: Function,
      default: function () { }
    }
  },
  data() {
    return {

    }
  },
  computed: {
    // 当前页多少条数据并且赋值给父组件
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    // 改变当前页几条数据得值赋值给父组件
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  created() {
    this.getInfo()
  },
  mounted() { },
  methods: {
    /**
     * @param {*}
     * @return {*}
     * @author: 刘恋
     * @Date: 2021-09-01 11:56:37
     * @description: 已选的数据项
     */
    handleSelectionChange(val) {
      this.$emit('selectVal', val)
    },
    getInfo() {
      // this.columns.unshift({
      //   type: 'index',
      //   label: '序号',
      //   width: 80,
      //   align: 'center'
      // })
    },
    handleSizeChange(val) {
      this.$emit('pagination', { pageIndex: 1, pageSize: val })
    },
    handleCurrentChange(val) {
      this.$emit('pagination', { pageIndex: val, pageSize: this.pageSize })
    },
    // 修改按钮的点击事件
    editClick(val) {
      this.$emit('edit', { item: val })
    },
    // 删除按钮点击事件
    deleteClick(val) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(val)
        this.$emit('del', { item: val })
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
  }

}
</script>

<style lang="scss">
.table-container {
  text-align: center;
  .el-pagination {
    margin-left: 0 !important;
    margin-top: 0 !important;
    background: #fff !important;
    height: 112px;
    line-height: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    ul {
      display: flex;
      justify-content: space-around;
      align-items: center;
      li {
        border-radius: 6px !important;
        color: #666666 !important;
      }
      .active {
        color: #fff !important;
        background: #1677ffff !important;
      }
    }
    button {
      border-radius: 6px !important;
      color: #666666 !important;
    }
  }
}
.table-container.el-table {
  margin-top: 20px;
}

.table-container .el-pagination {
  margin-top: 20px;
  margin-left: 20px;
}
</style>

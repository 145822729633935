<!--
* @description 计数器动画
* @fileName CountTo.vue
* @author liulian
* @date 2021/08/04 11:56:35
!-->
<template>
  <div>
    <span>
      <count-to
        :startVal="startVal"
        :decimal="decimal"
        :decimals="decimals"
        :endVal="endVal"
        :separator="separator"
        :duration="duration"
        :prefix="prefix"
        :suffix="suffix"
        :useEasing="true"
      ></count-to>
    </span>
  </div>
</template>

<script>
export default {
  name: 'CountTo',
  props: {
    // 开始的数字
    start: {
      type: Number,
      default: 30
    },
    // 结束的数字
    end: {
      type: Number,
      default: 120
    },
    // 小数后面需要显示的位数要显示的位数
    dec: {
      type: Number,
      default: 0
    },
    // 分割小数和整数之前的字符，默认以.来分割
    demal: {
      type: String,
      default: ''
    },
    // 千位之间的分割，默认以，来分割
    sep: {
      type: String,
      default: ''
    },
    // 数字前面字符
    pre: {
      type: String,
      default: ''
    },
    // 单位
    suf: {
      type: String,
      default: ''
    },
    // 动画开始的时间
    duration: {
      type: Number,
      default: 3000
    }
  },
  components: {
    'count-to': () => import("vue-count-to")
  },
  data() {
    return {
      startVal: this.start, //开始的数字
      endVal: this.end, // 结束的数字
      decimals: this.dec, // 要显示的位数
      decimal: this.demal, // 分割小数和整数之前的字符，默认以.来分割
      separator: this.sep, // 千位之间的分割，默认以，来分割
      prefix: this.pre, // 数字前面字符
      suffix: this.suf, // 单位
    }
  },
  watch: {
    end: function (val) {
      this.endVal = val
    }
  },
  computed: {},
  created() {
  },
  mounted() { },
  methods: {}
}
</script>

<style scoped lang="scss">
</style>
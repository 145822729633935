import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

// 安装elementui
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
Vue.use(ElementUI)

// 全局引入css文件
import common from "./assets/css/common.css"
import index from "./assets/css/index.css"
Vue.use(common)
Vue.use(index)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")

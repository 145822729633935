import request from "@/utils/request"

// 单日接单
export function getOrderNumApi(data) {
  return request({
    url: "/api/showDate/getOrderNum",
    method: "get",
    data,
  })
}

// 柱状图
export function getHistogramApi(data) {
  return request({
    url: "/api/showDate/getHistogram",
    method: "get",
    data,
  })
}

// 地图信息
export function getMapApi(data) {
  return request({
    url: "/api/showDate/chinaMap",
    method: "get",
    data,
  })
}

// 根据设备的id获取客户信息
export function getCustomerApi(data) {
  return request({
    url: "/api/showDate/deviceAndCustomerInfo",
    method: "get",
    params: data,
  })
}

// 平均分
export function getAvgScoreApi(data) {
  return request({
    url: "/api/showDate/avgScore",
    method: "get",
    data,
  })
}

// 百分比
export function getArtisanAndDeviceAllApi(data) {
  return request({
    url: "/api/showDate/artisanAndDeviceAll",
    method: "get",
    data,
  })
}

<!--
* @description 
* @fileName HelloWorld.vue
* @author *恋*
* @date 2021/10/19 15:29:34
!-->
<template>
  <div class="echarts-container">
    <el-container>
      <el-header>
        <span class="header-span f-white-color f-size-22">售后跟踪管理系统大屏</span>
      </el-header>
      <!-- 内容区域 -->
      <el-main>
        <el-row class="flex-row content-row" :gutter="20">
          <!-- 维修人员排行 -->
          <el-col :span="6">
            <div class="left">
              <div class="title text-align-right padding-right-20">
                <span class="weixiu">维修人员排行</span>
              </div>
              <!-- 维修排行 -->
              <div class="con">
                <maintenance-ranking></maintenance-ranking>
              </div>
            </div>
          </el-col>
          <el-col :span="18">
            <el-row class="right flex-column-between" :gutter="20">
              <el-col :sapn="18">
                <div class="right-top">
                  <el-row :gutter="24">
                    <el-col :span="16" class="map-col">
                      <!-- 地图显示区域 -->
                      <div ref="mapEchart" class="mapEchart" style></div>
                    </el-col>
                    <el-col :span="8">
                      <!-- 设备信息 -->
                      <div class="device-info">
                        <div class="title text-align-right padding-right-20 padding-bottom-10">
                          <span class="block shebei">设备信息</span>
                        </div>
                        <div class="divive-info-con text-align-left">
                          <el-row class="info-cont padding-left-20 margin-top-20">
                            <span class="f-size-16 f-gray-color padding-left-10">设备名称</span>
                            <div class="content margin-top-20 f-size-16 f-weight-600">
                              <!-- {{deviceInfo.deviceName}} -->
                              {{deviceInfo.deviceTypeName}}
                            </div>
                          </el-row>
                          <el-row class="info-cont padding-left-20 margin-top-20">
                            <span class="f-size-16 f-gray-color padding-left-10">运营总时长</span>
                            <div class="content margin-top-20 f-size-24 f-weight-600 flex-row">
                              <!-- <count-to :end="deviceInfo.deviceRuntime">{{deviceInfo.deviceRuntime}}</count-to> -->
                              {{deviceInfo.deviceRuntime}}
                              <!-- <count-to :end="120">{{deviceInfo.deviceRuntime}}</count-to>:
                              <count-to :end="10">10</count-to>:
                              <count-to :end="10">10</count-to>-->
                            </div>
                          </el-row>
                          <el-row class="info-cont padding-left-20 margin-top-20">
                            <span class="f-size-16 f-gray-color padding-left-10">设备状态码</span>
                            <div class="content margin-top-20 f-size-24 f-weight-600">
                              <!-- <count-to :end="deviceInfo.deviceStatus">{{deviceInfo.deviceStatus}}</count-to> -->
                              <count-to :end="1601">1601</count-to>
                            </div>
                          </el-row>
                        </div>
                      </div>
                      <!-- 客户信息 -->
                      <div class="customer-info margin-top-20">
                        <div class="title text-align-right padding-right-20">
                          <span class="block kehu">客户信息</span>
                        </div>
                        <div class="customer-info-content">
                          <el-row class="padding-left-20 margin-top-20" :gutter="10">
                            <el-col :span="4" class="text-align-left">
                              <img :src="customerInfo.userAvatar" alt />
                            </el-col>
                            <el-col :span="20">
                              <div class="text-align-left">
                                <span class="f-size-18">{{customerInfo.customerName}}</span>
                                <p class="margin-top-10 f-size-16">{{customerInfo.customerPhone}}</p>
                              </div>
                            </el-col>
                          </el-row>
                          <el-row class="padding-left-20 margin-top-20">
                            <el-col :span="24" class="text-align-left">
                              <p>地址：{{customerInfo.customerAddress}}</p>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <!-- 数据统计 -->
              <el-col :span="24">
                <div class="right-bottom margin-top-20">
                  <div class="title text-align-right padding-right-20 margin-top-10">
                    <span class="block">数据统计</span>
                  </div>
                  <!-- 图表 -->
                  <el-row class="echart-box">
                    <el-col :span="7" class="flex-row padding-left-10">
                      <div ref="bottom-one" style="width:50%;height:100%"></div>
                      <div class="right-info text-align-left margin-top-20 margin-left-10">
                        <p class="f-gray-color f-size-18 margin-top-20 padding-top-10">总维修人数</p>
                        <p
                          class="f-size-24 f-white-color margin-top-20 f-weight-600 flex-row align-end"
                        >
                          <count-to :end="pieInfo.artisanAll">{{pieInfo.artisanAll}}</count-to>
                          <span class="f-size-14 f-yellow-color margin-left-10">人</span>
                        </p>
                      </div>
                    </el-col>
                    <el-col :span="7" class="flex-row">
                      <div ref="bottom-two" style="width:50%;height:100%"></div>
                      <div
                        class="right-info text-align-left margin-top-20 padding-top-10 margin-left-10"
                      >
                        <p class="f-gray-color f-size-18 margin-top-20">总设备数</p>
                        <p
                          class="f-size-24 f-white-color margin-top-20 f-weight-600 flex-row align-end"
                        >
                          <count-to :end="pieInfo.deviceAll">{{pieInfo.deviceAll}}</count-to>
                          <span class="f-size-14 f-yellow-color margin-left-10">台</span>
                        </p>
                      </div>
                    </el-col>
                    <el-col :span="7">
                      <div ref="bottom-three" style="width:100%;height:100%"></div>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import * as echarts from 'echarts'

import { mapCharts, bareCharts, totalMaintenance, totalDevice } from "@/utils/echarts"
import MaintenanceRanking from './MaintenanceRanking.vue'
import { getHistogramApi, getMapApi, getCustomerApi, getArtisanAndDeviceAllApi } from "@/api/api"
import CountTo from './common/CountTo.vue'
export default {
  name: 'HelloWorld',
  components: {
    MaintenanceRanking,
    CountTo
  },
  data() {
    return {
      timer1: null,
      bareChartsOption: {}, // 柱状图
      mapChartsOption: [], // 地图数据
      devicesId: null, // 设备id
      customerInfo: {}, // 当前设备的客户信息
      deviceInfo: {}, // 设备信息
      pieInfo: {}, // 饼图中的信息
      ids: [], // 存储所有的点的id，
      customerlist: [], // 存储客户信息
    }
  },
  computed: {},
  created() {
    this.getInfo()
  },
  mounted() {
    this.getMap()
  },
  beforeDestroy() {
    clearInterval(this.timer1)
    this.timer1 = null
  },
  methods: {
    getInfo() {
      let _this = this
      // 柱状图
      getHistogramApi().then((res) => {
        if (res.code === 200) {
          _this.bareChartsOption = res.data
          this.getBarChart()
        }
      })

      // 获取百分比
      getArtisanAndDeviceAllApi().then((res) => {
        if (res.code === 200) {
          this.pieInfo = res.data
          this.getChart()
        }
      })
      // 获取地图信息
      getMapApi().then((res) => {
        if (res.code === 200) {
          res.data.map((item) => {
            console.log('item', item)
            _this.ids.push(item.id)
            _this.mapChartsOption.push(
              {
                id: item.id,
                deviceName: item.deviceName,
                deviceTypeName: item.deviceTypeName,
                jingweidu: item.deviceLatLng.map((i) => +i),
                deviceStatus: +item.deviceStatus,
                deviceRuntime: +item.deviceRuntime,
                deviceQrImg: item.deviceQrImg,
                deviceRuntime: item.deviceRuntime
              }
            )

            _this.getMap()
          })
          if (this.ids.length) {
            // 获取客户信息
            this.ids.map((id) => {
              this.getDeviceInfo(id)
            })
          }
        }
      })

    },
    getMap() {
      let _this = this
      let deviceId = null
      // 地图
      let mapChart = echarts.init(this.$refs['mapEchart'])
      mapChart.setOption(mapCharts(this.mapChartsOption))
      window.addEventListener('resize', function () {
        mapChart.resize()
      })
      let series = mapChart.getOption().series
      let arr = []
      for (let i = 0; i < series.length; i++) {
        for (let j = 0; j < series[i].data.length; j++) {
          // id不为空
          let deviceId = null
          let len = series[i].data[j].value
          deviceId = len[len.length - 1]
          arr.push(
            {
              seriesIndex: i,
              dataIndex: j,
              deviceInfo: len[3],
              deviceId
            }
          )
        }
      }
      function initMap() {
        for (let i = 0; i < arr.length; i++) {
          setTimeout(() => {
            mapChart.dispatchAction({
              type: 'showTip',
              seriesIndex: arr[i].seriesIndex,
              dataIndex: arr[i].dataIndex
            });
            // 获取设备信息
            deviceId = arr[i].deviceId
            _this.deviceInfo = arr[i].deviceInfo
            console.log('=device', _this.deviceInfo)
            // 当前点的id等与上一次请求客户信息的id
            _this.customerlist.map((v) => {
              if (v.deviceId === deviceId) {
                _this.customerInfo = v
              }
            })
          }, i * 3000)
        }
      }
      initMap()
      const timNum = arr.length
      setInterval(function () {
        initMap()
      }, timNum * 3000)

    },
    // 获取柱状图
    getBarChart() {
      let _this = this
      // 柱状图
      let barOptionChart = echarts.init(this.$refs['bottom-three']);
      barOptionChart.setOption(bareCharts(this.bareChartsOption))
      window.addEventListener('resize', function () {
        barOptionChart.resize()
      })
      this.timer1 = setInterval(function () {
        barOptionChart.clear()
        barOptionChart.setOption(bareCharts(_this.bareChartsOption))
      }, 8000)
    },
    getDeviceInfo(deviceId) {
      let _this = this
      // 根据id获取客户信息
      getCustomerApi({
        id: deviceId
      }).then((res) => {
        if (res.code === 200) {
          // 存储客户信息
          let obj = { deviceId: deviceId }
          _this.customerlist.push(Object.assign(res.data, obj))
        }
      })
    },
    getChart() {
      let _this = this
      // 总维修数
      let totalMaintenanceChart = echarts.init(this.$refs['bottom-one']);
      // 总设备数
      let totalDeviceChart = echarts.init(this.$refs['bottom-two']);
      totalMaintenanceChart.setOption(totalMaintenance(this.pieInfo))
      totalDeviceChart.setOption(totalDevice(this.pieInfo))
      window.addEventListener('resize', function () {
        totalMaintenanceChart.resize()
        totalDeviceChart.resize()
      })
      this.timer1 = setInterval(function () {
        totalMaintenanceChart.clear()
        totalDeviceChart.clear()
        totalMaintenanceChart.setOption(totalMaintenance(_this.pieInfo))
        totalDeviceChart.setOption(totalDevice(_this.pieInfo))
      }, 8000)
    }
  }
}
</script>

<style scoped lang="scss">
.echarts-container {
  background: linear-gradient(#060033, #000001);

  .el-header {
    text-align: center;
    height: 55px;
    line-height: 55px;
    .header-span {
      background-image: url("../assets/images/header-bg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: block;
      /* background: red; */
      height: 73px;
      line-height: 77px;
      width: 58%;
      margin: auto;
    }
  }
  .el-main {
    text-align: center;
    .content-row {
      .el-col {
        color: #fff;
        .left {
          height: 847px;
          background-image: url("../assets/images/left-bg.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          .con {
            overflow: hidden;
          }
          .title {
            display: block;
            height: 20px;
            line-height: 20px;
            padding-top: 5px;
          }
          // 表格样式
          el-table tr {
            background: transparent !important;
          }
        }
        .right {
          min-height: 810px;
          padding-bottom: 0;
          .right-top {
            flex: 1 0 auto;
            .device-info {
              height: 358px;
              background-image: url("../assets/images/right-top-top-bg.png");
              background-repeat: no-repeat;
              background-size: 100% 100%;
              .title {
                span {
                  height: 30px;
                  line-height: 30px;
                }
              }
              .divive-info-con {
                height: 80%;
                .el-row {
                  height: 80px;
                }
                .info-cont {
                  span {
                    border-left: 4px solid #3186d9;
                    height: 18px;
                    display: block;
                    line-height: 18px;
                  }
                }
              }
            }
            .customer-info {
              height: 186px;
              background-image: url("../assets/images/right-top-bottom-bg.png");
              background-repeat: no-repeat;
              background-size: 100% 100%;
              .title {
                height: 30px;
                line-height: 30px;
              }
              .customer-info-content {
                .el-row {
                  .el-col {
                    img {
                      width: 60px;
                      height: 60px;
                      border-radius: 100%;
                    }
                  }
                }
              }
            }
          }
          .right-bottom {
            height: 262px;
            display: flex;
            flex-direction: column;
            margin-bottom: 0px;
            background-image: url("../assets/images/right-bottom-bg.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            .title {
              span {
                height: 30px;
                line-height: 16px;
              }
            }
            .echart-box {
              flex: 1 0 auto;
              .el-col {
                height: 100%;
              }
              .right-info {
                width: 200px;
              }
            }
          }
          .map-col {
            height: 563px;
          }
          .mapEchart {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>

<!--
* @description 维修人员排行
* @fileName MaintenanceRanking.vue
* @author *恋*
* @date 2021/10/19 17:52:14
!-->
<template>
  <div class="maintentanceRank-container">
    <div class="content">
      <el-tabs type="border-card" id="tab" ref="tab" v-model="tabValue">
        <el-tab-pane
          :label="item.label"
          :name="item.name"
          v-for="(item,index) in tabMenuList"
          :key="index"
          class="el-tab-pane"
        >
          <div v-if="tabValue==='平均分'">
            <!--平均分 -->
            <table-list
              class="eTable"
              :showHeader="false"
              :indexShow="false"
              :data="list1"
              :columns="columns1"
            ></table-list>
          </div>
          <div v-else>
            <!-- 每日接单 -->
            <table-list
              class="eTable"
              :showHeader="false"
              :indexShow="false"
              :data="list2"
              :columns="columns2"
            ></table-list>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import TableList from './common/TableList.vue'
import { getOrderNumApi, getAvgScoreApi } from "@/api/api"
export default {
  name: 'MaintenanceRanking',
  components: { TableList },
  data() {
    return {
      tabMenuList: [
        {
          label: '平均分',
          name: '平均分',
        },
        {
          label: '单日接单',
          name: '单日接单',
        }
      ],
      tabValue: '平均分', //tab选项
      columns1: [
        {
          prop: 'index',
          width: 50,
          render(h, params) {
            if (params.row.index <= 3) {
              return h('span', {
                style: 'background:#febd3b;borderRadius:100%; width:20px;height:20px;display:block;textAlign:center;lineHeight:20px;color:#fff'
              }, params.row.index)
            } else {
              return h('span', {
                style: 'background:#064e9f;borderRadius:100%; width:20px;height:20px;display:block;textAlign:center;lineHeight:20px;color:#fff'
              }, params.row.index)
            }
          }
        },
        {
          prop: 'userAvatar',
          width: 90,
          render(h, params) {
            return h('img', {
              attrs: {
                src: params.row.userAvatar
              },
              style: "borderRadius:100%;width:60px;height:60px"
            })
          },
        },
        {
          prop: 'userName',
          align: 'left',
          render(h, params) {
            return h('div', [
              h('p', {

              }, params.row.userName),
              h('p', {

              }, params.row.phone)
            ])
          }
        }
      ],
      columns2: [
        {
          prop: 'index',
          width: 50,
          render(h, params) {
            if (params.row.index <= 3) {
              return h('span', {
                style: 'background:#febd3b;borderRadius:100%; width:20px;height:20px;display:block;textAlign:center;lineHeight:20px;color:#fff'
              }, params.row.index)
            } else {
              return h('span', {
                style: 'background:#064e9f;borderRadius:100%; width:20px;height:20px;display:block;textAlign:center;lineHeight:20px;color:#fff'
              }, params.row.index)
            }
          }
        },
        {
          prop: 'userAvatar',
          width: 90,
          render(h, params) {
            return h('img', {
              attrs: {
                src: params.row.userAvatar
              },
              style: "borderRadius:100%;width:60px;height:60px"
            })
          },
        },
        {
          prop: 'username',
          align: 'left',
          render(h, params) {
            return h('div', [
              h('p', {

              }, params.row.username),
              h('p', {

              }, params.row.phone)
            ])
          }
        }
      ],
      list1: [], // 平均分
      list2: [], // 每日接单
      timer1: null
    }
  },
  computed: {},
  created() {
    this.getInfo()
  },
  watch: {},
  beforeDestroy() {
    clearInterval(this.timer1)
    this.timer1 = null
  },
  mounted() {
    this.getTimer()
  },
  methods: {
    getInfo() {
      // 单日接单
      getOrderNumApi().then((res) => {
        if (res.code === 200) {
          this.list2 = res.data
          this.list2.map((i, index) => {
            return i.index = index + 1
          })
        }
      })
      //平均分
      getAvgScoreApi().then((res) => {
        if (res.code === 200) {
          this.list1 = res.data
          this.list1.map((i, index) => {
            return i.index = index + 1
          })
        }
      })
    },
    /**
     * @param {*}
     * @return {*}
     * @author: 刘恋
     * @Date: 2021-10-21 10:45:46
     * @description: 定时切换tabs菜单
     */
    getTimer() {
      //循环定时器
      this.timer1 = setInterval(function () {
        let tabs = document.getElementsByClassName('el-tabs__item is-top')
        if (this.tabValue === '平均分') {
          let e = document.createEvent("MouseEvents")
          e.initEvent('click', true, true)
          tabs[0].dispatchEvent(e)
          this.tabValue = '单日接单'
        } else {
          this.tabValue = '平均分'
          let e = document.createEvent("MouseEvents")
          e.initEvent('click', true, true)
          tabs[1].dispatchEvent(e)
        }
      }, 3000);
    }
  }
}
</script>

<style  lang="scss">
.maintentanceRank-container {
  padding-top: 10px;
  .el-tabs__item {
    font-weight: 400;
    height: 30px;
    border-right: none;
    line-height: 30px;
  }
  .el-tabs--border-card {
    background: none;
    border: none;
  }
  .el-tabs--border-card > .el-tabs__header {
    background-color: transparent !important;
    border-bottom: transparent !important;
  }
  .el-tabs--border-card > .el-tabs__header.is-active {
    background-color: transparent !important;
    border-bottom: transparent !important;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    background-color: transparent;
    // border: none;
    color: #fff;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item:hover {
    color: #fff !important;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    border: none;
  }
  .el-tabs__item:last-child .is-active {
    border: none;
  }
  .el-tabs__item:first-child:after {
    content: "";
    width: 1px;
    height: 1px;
    border: 1px solid #3a3e5d;
    margin-left: 20px;
  }
  .el-tabs__item:nth-child(2) {
    padding-left: 0 !important;
  }

  .el-tabs--border-card > .el-tabs__content {
    padding: 0;
    height: 761px;
    margin: auto;
    background: none;
    width: 95% !important;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .el-table {
    margin: auto;
    height: 761px;
    background: none;
  }
  .el-table__cell {
    // background: rgba(9, 19, 62, 1) !important;
    border-bottom: none !important;
    color: #858a9f;
  }
  .el-table,
  .el-table__expanded-cell {
    background-color: transparent;
  }

  el-table th,
  .el-table tr {
    background-color: transparent;
  }
  .el-table::before {
    background-color: transparent;
  }
  .el-tabs__item {
    font-weight: 400;
    font-size: 16px;
  }
}
</style>
